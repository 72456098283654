.gradientbody {
	position: fixed;
	background: linear-gradient(180deg, rgba(0,11,90,1) 0%, rgba(139,0,0,1) 20%, rgba(171,132,0,1) 26%, rgba(0,142,180,1) 46%, rgba(0,34,90,1) 100%);
	background-size: 100% 800%;
	animation: gradient 120s linear infinite reverse;
	height: 100vh;
	z-index: -2;
}

@keyframes gradient {
	0% {
		background-position: 0% 0%;
	}
	50% {
		background-position: 0% 100%;
	}
	100% {
		background-position: 0% 0%;
	}
}

#image {
	animation: color-pulsate 240s linear  infinite;
}

#image-light {
	animation: color-light 240s linear  infinite;
}

@keyframes color-pulsate{
	0% 	 { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.25rem rgba(255, 255, 255, 0.7)) }
	25%	 { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.55rem rgba(255, 255, 255, 0.4)) }
	50%  { -webkit-filter:  hue-rotate(0)      saturate(500%) drop-shadow(0 0 1.75rem rgba(255, 255, 255, 0.8)) }
	75%  { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.55rem rgba(255, 255, 255, 0.4)) }
	100% { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.25rem rgba(255, 255, 255, 0.7)) }
}

@keyframes color-light{
	0% 	 { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5)) }
	25%	 { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.55rem rgba(0, 0, 0, 0.3)) }
	50%  { -webkit-filter:  hue-rotate(0)      saturate(500%) drop-shadow(0 0 1.75rem rgba(0, 0, 0, 0.6)) }
	75%  { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.55rem rgba(0, 0, 0, 0.3)) }
	100% { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.5)) }
}

#image-small {
	animation: color-pulsate 240s linear  infinite;
}

#image-light-small {
	animation: color-light 240s linear  infinite;
}


@keyframes color-pulsate-small{
	0% 	 { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.012rem rgba(255, 255, 255, 0.7)) }
	25%	 { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.015rem rgba(255, 255, 255, 0.4)) }
	50%  { -webkit-filter:  hue-rotate(0)      saturate(500%) drop-shadow(0 0 0.017rem rgba(255, 255, 255, 0.8)) }
	75%  { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.015rem rgba(255, 255, 255, 0.4)) }
	100% { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.012rem rgba(255, 255, 255, 0.7)) }
}

@keyframes color-light-small{
	0% 	 { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.02rem rgba(0, 0, 0, 0.5)) }
	25%	 { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.05rem rgba(0, 0, 0, 0.3)) }
	50%  { -webkit-filter:  hue-rotate(0)      saturate(500%) drop-shadow(0 0 0.08rem rgba(0, 0, 0, 0.6)) }
	75%  { -webkit-filter:  hue-rotate(180deg) saturate(200%) drop-shadow(0 0 0.05rem rgba(0, 0, 0, 0.3)) }
	100% { -webkit-filter:  hue-rotate(0)      saturate(100%) drop-shadow(0 0 0.02rem rgba(0, 0, 0, 0.5)) }
}